import axios from 'axios';
import React, { createContext, FunctionComponent, useEffect, useState } from 'react';

interface IConfig {
  urls: {
    website: string;
    merchant: string;
    admin: string;
    api: string;
  };
  features: {
    photoCredits?: boolean;
    support?: boolean;
    pilot?: boolean;
  };
  social: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    github?: string;
    linkedin?: string;
  };
  version: string;
  environment: string;
  loaded: boolean;
}

function urlBuilder(subdomain = '') {
  return `https://${subdomain ? `${subdomain}.` : ''}numeropay.com`;
}

const defaultContext: IConfig = {
  urls: {
    website: urlBuilder(),
    merchant: urlBuilder('mechant'),
    admin: urlBuilder('admin'),
    api: urlBuilder('api'),
  },
  features: {
    pilot: true,
  },
  social: {
    facebook: 'numeropay',
    twitter: 'NumeroPay',
    instagram: 'numeropay',
    github: 'numero-pay',
    linkedin: 'numero-pay',
  },
  version: 'not-set',
  environment: 'not-set',
  loaded: false,
};

export const ConfigContext = createContext<IConfig>(defaultContext);

export const ConfigContextProvider: FunctionComponent = ({ children }) => {
  const [config, setConfig] = useState(defaultContext);

  function setConfigContext(newConfig: Partial<IConfig>) {
    setConfig(Object.assign({}, config, newConfig));
  }

  useEffect(() => {
    axios.get('/version.json').then((response) => {
      setConfigContext({
        ...response.data.config,
        version: response.data.version || 'v0.0.0-development',
        environment: response.data.environment || 'dev',
        loaded: true,
      });
    });
  }, []);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
